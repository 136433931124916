import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { useState } from 'react'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Button,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap'

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import { FilterTabContent } from './components/FilterTabContent'

const TableFilter = ({
    t,
    tabs,
    defaultActiveTab,
    onOkButtonClicked,
    className
}) => {
    const [activeTab, setActiveTab] = useState(
        tabs.find((tab) => tab.name === defaultActiveTab) || tabs[0]
    )
    const [filters, setFilters] = useState({})

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => setDropdownOpen((prevState) => !prevState)

    const tabFilterChanged = ({ filterName, value }) => {
        setFilters((currentState) => {
            const newState = { ...currentState }
            newState[filterName] = value

            return newState
        })
    }

    const resetFilters = () => {
        setFilters({})

        onOkButtonClicked && onOkButtonClicked()
        toggle()
    }

    React.useEffect(() => {
        setActiveTab(
            tabs.find(
                (tab) => tab.name === (activeTab?.name || defaultActiveTab)
            ) || tabs[0]
        )
    }, [tabs])

    return (
        <div className={`custom-table-filter ${className}`}>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret color='secondary' outline>
                    <i className='ri-filter-3-fill' />
                    <span>{t('Filter')}</span>
                </DropdownToggle>

                <DropdownMenu className='dropdown-menu-end'>
                    <div className='filters-container'>
                        <div className='search-input d-none'>
                            <InputGroup>
                                <Form.Control
                                    placeholder='Search'
                                    aria-describedby='search-result-input'
                                    style={{
                                        borderRight: 'none',
                                        fontWeight: 700,
                                        color: '#4F4F4F'
                                    }}
                                    onChange={(e) =>
                                        console.log(e.target.value)
                                    }
                                />

                                <InputGroup.Text
                                    className='bg-transparent border-left-0'
                                    id='search-result-input'
                                    style={{ borderLeft: 'none' }}
                                >
                                    <i className='ri-search-line' />
                                </InputGroup.Text>
                            </InputGroup>
                        </div>

                        <div className='navs-container d-flex justify-content-center'>
                            <Nav tabs>
                                {tabs.map((tab, index) => {
                                    return (
                                        <NavItem
                                            key={index}
                                            onClick={() => setActiveTab(tab)}
                                            className={`${
                                                activeTab?.name === tab.name &&
                                                'active'
                                            }`}
                                        >
                                            <NavLink>
                                                <span>{t(tab.title)}</span>
                                            </NavLink>
                                        </NavItem>
                                    )
                                })}
                            </Nav>
                        </div>

                        <div className='navs-content-container'>
                            <FilterTabContent
                                {...activeTab}
                                defaultValue={filters[activeTab.name]}
                                onFilterChange={tabFilterChanged}
                            />
                        </div>

                        <div className='actions-container gap-3 d-flex align-items-center justify-content-end'>
                            <Button
                                color='primary'
                                size='sm'
                                onClick={() => {
                                    onOkButtonClicked &&
                                        onOkButtonClicked(filters)
                                    toggle()
                                }}
                            >
                                {t('ok')}
                            </Button>

                            <Button
                                onClick={resetFilters}
                                color='secondry'
                                size='sm'
                            >
                                {t('Reset')}
                            </Button>
                        </div>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

export default withNamespaces()(TableFilter)
