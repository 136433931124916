import * as React from 'react'
import { DateRangePicker } from 'react-date-range'

const DateFilter = ({ name, defaultValue, onFilterChange }) => {
    const [values, setValues] = React.useState({
        from: defaultValue?.from ? new Date(defaultValue?.from) : null,
        to: defaultValue?.to ? new Date(defaultValue?.to) : null
    })

    React.useEffect(() => {
        if (values.from && values.to) {
            onFilterChange &&
                onFilterChange({
                    filterName: name,
                    value: values
                })
        }
    }, [values])

    return (
        <DateRangePicker
            ranges={[
                {
                    startDate: values.from,
                    endDate: values.to,
                    key: name
                }
            ]}
            onChange={(range) => {
                setValues({
                    from: range[name].startDate,
                    to: range[name].endDate
                })
            }}
            rangeColors={['#5664d2']}
        />
    )
}

export { DateFilter }
