import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { withNamespaces } from 'react-i18next'

const Navigator = (props) => {
    const { t, backButtonOptions, breadCrumbs = [] } = props
    const history = useHistory()

    const backButtonDefaultOptions = {
        enable: false,
        title: props.t('Back'),
        icon: <i className='ri-arrow-left-fill' />,
        onClick: () => history.goBack()
    }

    const backButtonResultOptions = backButtonOptions
        ? backButtonOptions(backButtonDefaultOptions)
        : backButtonDefaultOptions

    return (
        <div className='custom-navigator'>
            <div className='navigator-actions'>
                {backButtonResultOptions?.enable && (
                    <Button
                        className='d-flex gap-1'
                        onClick={(e) => {
                            backButtonResultOptions?.onClick &&
                                backButtonResultOptions.onClick(e)
                        }}
                        outline
                        color='secondary'
                        size='sm'
                    >
                        {backButtonResultOptions?.icon && (
                            <React.Fragment>
                                {backButtonResultOptions.icon}
                            </React.Fragment>
                        )}
                        {t(backButtonResultOptions?.title)}
                    </Button>
                )}
            </div>

            <div className='navigator-breadcrumbs'>
                <Breadcrumb>
                    {(breadCrumbs || []).map((breadItem, index) => {
                        return (
                            <BreadcrumbItem key={index}>
                                <Link to={breadItem.link}>
                                    {breadItem.title}
                                </Link>
                            </BreadcrumbItem>
                        )
                    })}
                </Breadcrumb>
            </div>
        </div>
    )
}
export default withNamespaces()(Navigator)
