const API_BASE_URL = `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api`

const API_URL_GET_ANALYST_AND_ADMIN_LIST =
    API_BASE_URL + '/analystAndAdminAnalyst.list'

const API_URL_FETCH_COUNTRIES_LIST = API_BASE_URL + '/report.country.list'

export {
    API_BASE_URL,
    API_URL_GET_ANALYST_AND_ADMIN_LIST,
    API_URL_FETCH_COUNTRIES_LIST
}
