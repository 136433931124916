import * as React from 'react'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'

const RadioList = ({
    name,
    data = [],
    isLoading,
    formatter,
    defaultValue,
    onFilterChange
}) => {
    const [value, setValue] = React.useState()
    const [items, setItems] = React.useState([])

    React.useEffect(() => {
        if (data && Array.isArray(data)) {
            const result = data.map((item) => {
                return {
                    id: item.id,
                    title: (formatter && formatter(item)) || ''
                }
            })

            result.unshift({
                id: 'all',
                title: 'All'
            })

            setItems(result)
        }
    }, [data])

    React.useEffect(() => {
        if (value) {
            onFilterChange &&
                onFilterChange({
                    filterName: name,
                    value: value
                })
        }
    }, [value])

    React.useEffect(() => {
        setValue(defaultValue || 'all')
    }, [defaultValue])

    return (
        <React.Fragment>
            {isLoading && (
                <div className='text-center'>
                    <Spinner animation='border' variant='primary' size='md' />
                </div>
            )}

            {!isLoading &&
                items.map((item, index) => {
                    return (
                        <div className='filter-item' key={index}>
                            <Form.Check
                                id={`${name}-item-${item.id}`}
                                type='radio'
                                checked={value === item.id}
                                onChange={() => {
                                    setValue(item.id)
                                }}
                                label={item.title}
                            />
                        </div>
                    )
                })}
        </React.Fragment>
    )
}

export { RadioList }
