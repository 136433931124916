import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Table
} from 'reactstrap'
import Select from 'react-select'
import Spinner from 'react-bootstrap/Spinner'
import { AvForm, AvField } from 'availity-reactstrap-validation'

const CreateAndEditModal = ({
    t,
    show,
    toggle,
    onSubmit,
    analystMemoList,
    countriesMemoList,
    parentEntitiesMemoList,
    submitInProgress = false,
    entity = null
}) => {
    const avFormRef = React.useRef()
    const [addressIndexes, setAddressIndexes] = React.useState([0])
    const [selectedAnalyst, setSelectedAnalyst] = React.useState(null)
    const [parentEntity, setSelectedParentEntity] = React.useState(null)
    const [selectedCountry, setSelectedCountry] = React.useState(null)

    React.useEffect(() => {
        if (entity) {
            setSelectedCountry({
                value: entity?.country?.id,
                label: entity?.country?.name
            })

            setSelectedParentEntity({
                value: entity?.parent?.id,
                label: entity?.parent?.title
            })

            setSelectedAnalyst({
                value: entity?.successor_analyst?.id,
                label: entity?.successor_analyst?.full_name
            })
        } else {
            setSelectedCountry(null)
            setSelectedAnalyst(null)
        }
    }, [show])

    return (
        <Modal size='lg' scrollable isOpen={show} backdrop='static'>
            <AvForm
                className='needs-validation'
                onValidSubmit={(e, values) => {
                    onSubmit &&
                        onSubmit(
                            {
                                ...values,
                                legalEntityLocations: entity
                                    ? undefined
                                    : (
                                          values.legalEntityLocations || []
                                      ).filter((item) => !!item)
                            },
                            entity ? 'edit' : 'create'
                        )
                }}
                ref={avFormRef}
            >
                <ModalHeader toggle={toggle}>
                    {entity ? (
                        <React.Fragment>{`${t('Entity')} ${
                            entity?.id
                        }`}</React.Fragment>
                    ) : (
                        <React.Fragment>{t('Add A New Entity')}</React.Fragment>
                    )}
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col sm='12' md='6' className='mb-3'>
                            <Label
                                className='form-label'
                                htmlFor='entity-company-full-name'
                            >
                                {t('Company Full Name')}:
                            </Label>

                            <AvField
                                name='title'
                                type='text'
                                errorMessage={t('This field cannot be blank')}
                                className='form-control'
                                validate={{
                                    required: {
                                        value: true
                                    }
                                }}
                                defaultValue={entity?.title}
                                id='entity-company-full-name'
                            />
                        </Col>
                        <Col sm='12' md='6' className='mb-3'>
                            <Label
                                className='form-label'
                                htmlFor='entity-company-short-name'
                            >
                                {t('Company Short Name')}:
                            </Label>

                            <AvField
                                name='shortName'
                                type='text'
                                errorMessage={t('This field cannot be blank')}
                                className='form-control'
                                validate={{
                                    required: {
                                        value: true
                                    }
                                }}
                                defaultValue={entity?.short_name}
                                id='entity-company-short-name'
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm='12' md='6' className='mb-3'>
                            <Label
                                className='form-label'
                                htmlFor='entity-company-dc-number'
                            >
                                {t('Debtor/Creditor number')}:
                            </Label>

                            <AvField
                                name='dcNumber'
                                type='text'
                                errorMessage={t('This field cannot be blank')}
                                className='form-control'
                                validate={{
                                    required: {
                                        value: true
                                    }
                                }}
                                defaultValue={entity?.dc_number}
                                id='entity-company-dc-number'
                            />
                        </Col>

                        <Col sm='12' md='6' className='mb-3'>
                            <Label
                                className='form-label'
                                htmlFor='entity-successor-analyst'
                            >
                                {t('Parent Entity')}:
                            </Label>
                            <Select
                                id='entity-parent'
                                className='mb-2'
                                placeholder={t('Select') + '...'}
                                classNamePrefix='select2-selection'
                                options={parentEntitiesMemoList}
                                menuPortalTarget={document.body}
                                defaultValue={parentEntity}
                                onChange={(e) => {
                                    const input =
                                        avFormRef.current._inputs.parent
                                    if (input) {
                                        input.value = e?.value
                                        input.validate()
                                    }
                                }}
                                styles={{
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999
                                    })
                                }}
                                isClearable
                            />
                            <AvField
                                type='hidden'
                                name='parent'
                                errorMessage={t('This field cannot be blank')}
                                defaultValue={parentEntity?.value}
                                validate={{
                                    required: {
                                        value: false
                                    }
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm='12' md='6' className='mb-3'>
                            <Label
                                className='form-label'
                                htmlFor='entity-successor-analyst'
                            >
                                {t('Assign By Default')}:
                            </Label>
                            <Select
                                id='entity-successor-analyst'
                                className='mb-2'
                                placeholder={t('Select') + '...'}
                                classNamePrefix='select2-selection'
                                options={analystMemoList}
                                menuPortalTarget={document.body}
                                defaultValue={selectedAnalyst}
                                onChange={(e) => {
                                    const input =
                                        avFormRef.current._inputs
                                            .successorAnalyst
                                    if (input) {
                                        input.value = e.value
                                        input.validate()
                                    }
                                }}
                                styles={{
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999
                                    })
                                }}
                            />
                            <AvField
                                type='hidden'
                                name='successorAnalyst'
                                errorMessage={t('This field cannot be blank')}
                                defaultValue={selectedAnalyst?.value}
                                validate={{
                                    required: {
                                        value: true
                                    }
                                }}
                            />
                            <AvField
                                type='hidden'
                                name='country'
                                errorMessage={t('This field cannot be blank')}
                                defaultValue={32}
                                validate={{
                                    required: {
                                        value: false
                                    }
                                }}
                            />
                        </Col>
                    </Row>

                    {!entity && (
                        <Row>
                            <Col sm='12' className='mb-3'>
                                <Label className='form-label'>
                                    {t('Address')}:
                                </Label>
                                <Table borderless>
                                    <tbody>
                                        {addressIndexes.map((addressIndex) => {
                                            return (
                                                <tr key={addressIndex}>
                                                    <td>
                                                        <Select
                                                            placeholder={
                                                                t('Country') +
                                                                '...'
                                                            }
                                                            classNamePrefix='select2-selection'
                                                            options={
                                                                countriesMemoList
                                                            }
                                                            menuPortalTarget={
                                                                document.body
                                                            }
                                                            width='150px'
                                                            onChange={(e) => {
                                                                const input =
                                                                    avFormRef
                                                                        .current
                                                                        ._inputs[
                                                                        `legalEntityLocations[${addressIndex}][country]`
                                                                    ]
                                                                if (input) {
                                                                    input.value =
                                                                        e.value
                                                                    input.validate()
                                                                }
                                                            }}
                                                            styles={{
                                                                menuPortal: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    zIndex: 9999
                                                                }),
                                                                control: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    minWidth:
                                                                        '160px'
                                                                })
                                                            }}
                                                        />
                                                        <AvField
                                                            type='hidden'
                                                            name={`legalEntityLocations[${addressIndex}][country]`}
                                                            errorMessage={t(
                                                                'This field cannot be blank'
                                                            )}
                                                            validate={{
                                                                required: {
                                                                    value: true
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <AvField
                                                            name={`legalEntityLocations[${addressIndex}][city]`}
                                                            type='text'
                                                            errorMessage={t(
                                                                'This field cannot be blank'
                                                            )}
                                                            placeholder='City'
                                                            className='form-control'
                                                            validate={{
                                                                required: {
                                                                    value: true
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <AvField
                                                            name={`legalEntityLocations[${addressIndex}][address]`}
                                                            type='text'
                                                            placeholder='address'
                                                            errorMessage={t(
                                                                'This field cannot be blank'
                                                            )}
                                                            className='form-control'
                                                            validate={{
                                                                required: {
                                                                    value: true
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            hidden={
                                                                addressIndexes.length <
                                                                2
                                                            }
                                                            size='sm'
                                                            color='danger'
                                                            outline
                                                            className='border-0'
                                                            type='button'
                                                            onClick={() => {
                                                                setAddressIndexes(
                                                                    (
                                                                        currentList
                                                                    ) => {
                                                                        return [
                                                                            ...currentList.filter(
                                                                                (
                                                                                    item
                                                                                ) => {
                                                                                    return (
                                                                                        item !==
                                                                                        addressIndex
                                                                                    )
                                                                                }
                                                                            )
                                                                        ]
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <i className='ri-delete-bin-line font-size-24' />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                                <div className='d-flex justify-content-end'>
                                    <Button
                                        color='primary'
                                        outline
                                        type='button'
                                        onClick={() => {
                                            setAddressIndexes((currentList) => {
                                                return [
                                                    ...currentList,
                                                    [...currentList]
                                                        .sort()
                                                        .reverse()[0] + 1
                                                ]
                                            })
                                        }}
                                    >
                                        <i className='ri-add-line me-2' />
                                        {t('Add Location')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    )}

                    <ModalFooter>
                        <Button
                            color='primary'
                            outline
                            className='waves-effect waves-light'
                            type='button'
                            onClick={toggle}
                            disabled={submitInProgress}
                        >
                            {t('Cancel')}
                        </Button>

                        <Button
                            color='primary'
                            type='submit'
                            className='waves-effect waves-light'
                            disabled={submitInProgress}
                        >
                            {submitInProgress && (
                                <Spinner
                                    animation='border'
                                    variant='danger'
                                    size='sm'
                                    className='me-1'
                                />
                            )}
                            {entity ? (
                                <React.Fragment>
                                    {t('Save Changes')}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {t('Add Entity')}
                                </React.Fragment>
                            )}
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </AvForm>
        </Modal>
    )
}

export default withNamespaces()(CreateAndEditModal)
