import axios from 'axios'
import { API_BASE_URL } from '../constants/'

export default class EntityManagementService {
    static instance

    static getInstance() {
        if (!EntityManagementService.instance) {
            EntityManagementService.instance = new EntityManagementService()
        }
        return EntityManagementService.instance
    }

    async fetchEntitesList(params = {}) {
        const response = await axios.get(
            `${API_BASE_URL}/entity_management/legal/list`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || []
    }

    async fetchTotalEntities() {
        const response = await axios.get(
            `${API_BASE_URL}/entity_management/legal/all`,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || []
    }

    async create(payload = {}) {
        const response = await axios.post(
            `${API_BASE_URL}/entity_management/legal/add`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data
    }

    async delete(entityId) {
        const response = await axios.delete(
            `${API_BASE_URL}/entity_management/legal/remove/${entityId}`,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async show(entityId, params = {}) {
        const response = await axios.get(
            `${API_BASE_URL}/entity_management/legal/show/${entityId}`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || {}
    }

    async edit(entityId, payload = {}) {
        const response = await axios.post(
            `${API_BASE_URL}/entity_management/legal/edit/${entityId}`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data
    }
}
