import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { withRouter, useParams, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
    Label,
    ListGroup,
    ListGroupItem,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from 'reactstrap'
import Spinner from 'react-bootstrap/Spinner'
import { useQuery, useMutation } from '@tanstack/react-query'
import { INTERNATIONAL_DATE_TIME_FORMAT } from 'src/common/constants'
import Navigator from '../../../../components/navigator'
import DateUtils from 'src/services/utils/DateUtils'
import { toast } from 'react-toastify'
import EntityManagementService from '../../../../api/EntityManagementService'
import Divisions from './components/divisions'
import Addresses from './components/addresses'
import CreateAndEditModal from '../components/CreateAndEditModal'
import CommonService from '../../../../api/CommonService'

const EntityDetail = ({ t }) => {
    const [showEntityDeletionModal, setShowEntityDeletionModal] =
        React.useState(false)
    const [showEntityEditForm, setShowEntityEditForm] = React.useState(false)
    const { entityId } = useParams()
    const dateUtils = new DateUtils()
    const history = useHistory()

    const {
        isFetching: entityDetailsIsFetching,
        data: entityDetails,
        refetch: refetchEntityDetail
    } = useQuery({
        queryKey: ['entity-management-fetch-entity-details-query'],
        queryFn: async () => {
            const service = EntityManagementService.getInstance()

            return await service.show(entityId)
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching details of entity.'), {
                type: 'error'
            })
        }
    })

    const { isFetching: analystsAreFetching, data: analystsList } = useQuery({
        queryKey: ['entity-management-fetch-admins-query'],
        queryFn: async () => {
            const service = CommonService.getInstance()

            return await service.fetchAnalystAndAdminAnalystList()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching analysts list.'), {
                type: 'error'
            })
        }
    })

    const { isFetching: countriesAreFetching, data: countriesList } = useQuery({
        queryKey: ['entity-management-fetch-countries-query'],
        queryFn: async () => {
            const service = CommonService.getInstance()

            return await service.fetchCountriesList()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching countries list.'), {
                type: 'error'
            })
        }
    })

    const { isFetching: totalEntitiesAreFetching, data: totalEntities } =
        useQuery({
            queryKey: ['entity-management-fetch-total-entities-query'],
            queryFn: async () => {
                const service = EntityManagementService.getInstance()

                return await service.fetchTotalEntities()
            },
            cacheTime: 0,
            refetchOnWindowFocus: false,
            onError: (_error) => {
                toast(t('An error occurred while fetching entities list.'), {
                    type: 'error'
                })
            }
        })

    const { isLoading: deleteEntityIsLoading, mutate: mutateEntityDelete } =
        useMutation({
            mutationFn: async () => {
                const service = EntityManagementService.getInstance()

                return await service.delete(entityId)
            },
            onSuccess: () => {
                toast(t('Entity deleted successfully.'), {
                    type: 'success'
                })

                history.push('/admin/entity-management/entities')
            },
            onError: () => {
                toast(t('Failed to delete entity.'), {
                    type: 'error'
                })
            }
        })

    const { isLoading: editEntityIsLoading, mutate: mutateEntityEdit } =
        useMutation({
            mutationFn: async (payload) => {
                const service = EntityManagementService.getInstance()

                return await service.edit(entityId, payload)
            },
            onSuccess: () => {
                refetchEntityDetail()
                setShowEntityEditForm(false)

                toast(t('Entity updated successfully.'), {
                    type: 'success'
                })
            },
            onError: () => {
                toast(t('Failed to edit entity.'), {
                    type: 'error'
                })
            }
        })

    const totalEntitiesMemoList = React.useMemo(() => {
        return (totalEntities?.legalEntities || []).map((item) => {
            return {
                label: item.title,
                value: item.id
            }
        })
    }, [totalEntities])

    const navigatorMemoBreads = React.useMemo(() => {
        return [
            {
                title: 'Entity Management',
                link: '/admin/entity-management/entities'
            },
            {
                title: 'Entities',
                link: `/admin/entity-management/entities`
            },
            {
                title: entityDetailsIsFetching ? '...' : entityDetails?.title,
                link: `#`
            }
        ]
    }, [entityDetailsIsFetching, entityDetails])

    const analystMemoList = React.useMemo(() => {
        return (analystsList || []).map((item) => {
            return {
                label: `${item.first_name} ${item.last_name}`,
                value: item.id
            }
        })
    }, [analystsList])

    const countriesMemoList = React.useMemo(() => {
        return (countriesList || []).map((item) => {
            return {
                label: item.name,
                value: item.id
            }
        })
    }, [countriesList])

    const renderModals = () => {
        const canMountEditModal = !(
            countriesAreFetching ||
            analystsAreFetching ||
            totalEntitiesAreFetching ||
            entityDetailsIsFetching
        )

        return (
            <React.Fragment>
                <Modal
                    size='lg'
                    scrollable
                    isOpen={showEntityDeletionModal}
                    backdrop='static'
                >
                    <ModalHeader
                        toggle={() =>
                            setShowEntityDeletionModal(!showEntityDeletionModal)
                        }
                    >
                        {t('Delete Entity')}
                    </ModalHeader>

                    <ModalBody>
                        <p>
                            {t('Are you sure you want to delete this entity?')}
                        </p>

                        <ModalFooter>
                            <Button
                                disabled={deleteEntityIsLoading}
                                color='primary'
                                outline
                                className='waves-effect waves-light'
                                type='button'
                                onClick={() => {
                                    setShowEntityDeletionModal(false)
                                }}
                            >
                                {t('Cancel')}
                            </Button>

                            <Button
                                disabled={deleteEntityIsLoading}
                                color='danger'
                                type='button'
                                className='waves-effect waves-light'
                                onClick={() => mutateEntityDelete()}
                            >
                                {deleteEntityIsLoading && (
                                    <Spinner
                                        animation='border'
                                        variant='danger'
                                        size='sm'
                                        className='me-1'
                                    />
                                )}
                                {t('Delete')}
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>

                {canMountEditModal && (
                    <CreateAndEditModal
                        show={showEntityEditForm}
                        toggle={() => {
                            setShowEntityEditForm(false)
                        }}
                        onSubmit={(values, action) => {
                            mutateEntityEdit(values)
                        }}
                        analystMemoList={analystMemoList}
                        countriesMemoList={countriesMemoList}
                        parentEntitiesMemoList={totalEntitiesMemoList}
                        entity={entityDetails}
                        submitInProgress={editEntityIsLoading}
                    />
                )}
            </React.Fragment>
        )
    }

    return (
        <div className='page-content entity-detail'>
            <Container fluid>
                <Row>
                    <Col lg='12' className='mb-4'>
                        <Navigator
                            backButtonOptions={(props) => {
                                return {
                                    ...props,
                                    enable: true
                                }
                            }}
                            breadCrumbs={navigatorMemoBreads}
                        />
                    </Col>

                    <Col lg='12'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm='12' md='8'>
                                        <Row>
                                            <Col sm='12'>
                                                <Card className='shadow-none divisions'>
                                                    <CardBody>
                                                        <h5>
                                                            {t('Divisions')}:
                                                        </h5>
                                                        <Divisions
                                                            entityId={entityId}
                                                        />
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm='12'>
                                                <Card className='shadow-none addresses'>
                                                    <CardBody>
                                                        <h5>{t('Address')}:</h5>
                                                        <Addresses
                                                            entityId={entityId}
                                                            isFetching={
                                                                entityDetailsIsFetching
                                                            }
                                                            list={
                                                                entityDetails?.legal_entity_locations
                                                            }
                                                            onRefreshList={() =>
                                                                refetchEntityDetail()
                                                            }
                                                        />
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col sm='12' md='4'>
                                        <div className='detail-box'>
                                            <div className='header-box'>
                                                <h5>{t('Details')}</h5>
                                            </div>
                                            <div className='d-flex justify-content-end align-items-center mt-1'>
                                                <Button
                                                    type='button'
                                                    size='sm'
                                                    color='primary'
                                                    outline
                                                    className='border-0'
                                                    onClick={() =>
                                                        setShowEntityEditForm(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <i className='ri-pencil-line font-size-24' />
                                                </Button>
                                                <Button
                                                    type='button'
                                                    size='sm'
                                                    color='danger'
                                                    outline
                                                    className='border-0'
                                                    onClick={() =>
                                                        setShowEntityDeletionModal(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <i className='ri-delete-bin-line font-size-24' />
                                                </Button>
                                            </div>
                                            <ListGroup>
                                                <ListGroupItem>
                                                    <Row>
                                                        <Col sm='12'>
                                                            <Label>
                                                                {t(
                                                                    'Debtor/Creditor number'
                                                                )}
                                                                :
                                                            </Label>
                                                        </Col>
                                                        <Col sm='12'>
                                                            {entityDetailsIsFetching ? (
                                                                <div className='dt-field dt-skeleton dt-select-list' />
                                                            ) : (
                                                                <p>
                                                                    {
                                                                        entityDetails?.dc_number
                                                                    }
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </ListGroupItem>
                                                <ListGroupItem>
                                                    <Row>
                                                        <Col sm='12'>
                                                            <Label>
                                                                {t(
                                                                    'Company Full Name'
                                                                )}
                                                                :
                                                            </Label>
                                                        </Col>
                                                        <Col sm='12'>
                                                            {entityDetailsIsFetching ? (
                                                                <div className='dt-field dt-skeleton dt-select-list' />
                                                            ) : (
                                                                <p>
                                                                    {
                                                                        entityDetails?.title
                                                                    }
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </ListGroupItem>
                                                <ListGroupItem>
                                                    <Row>
                                                        <Col sm='12'>
                                                            <Label>
                                                                {t(
                                                                    'Company Short Name'
                                                                )}
                                                                :
                                                            </Label>
                                                        </Col>
                                                        <Col sm='12'>
                                                            {entityDetailsIsFetching ? (
                                                                <div className='dt-field dt-skeleton dt-select-list' />
                                                            ) : (
                                                                <p>
                                                                    {
                                                                        entityDetails?.short_name
                                                                    }
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </ListGroupItem>
                                                <ListGroupItem>
                                                    <Row>
                                                        <Col sm='12'>
                                                            <Label>
                                                                {t(
                                                                    'Assign By Default'
                                                                )}
                                                                :
                                                            </Label>
                                                        </Col>
                                                        <Col sm='12'>
                                                            {entityDetailsIsFetching ? (
                                                                <div className='dt-field dt-skeleton dt-select-list' />
                                                            ) : (
                                                                <p>
                                                                    {
                                                                        entityDetails
                                                                            ?.successor_analyst
                                                                            ?.full_name
                                                                    }
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </ListGroupItem>
                                                <ListGroupItem>
                                                    <Row>
                                                        <Col sm='12'>
                                                            <Label>
                                                                {t('Updated')}:
                                                            </Label>
                                                        </Col>
                                                        <Col sm='12'>
                                                            {entityDetailsIsFetching ? (
                                                                <div className='dt-field dt-skeleton dt-select-list' />
                                                            ) : (
                                                                <p className='d-flex justify-content-between align-items-center'>
                                                                    {dateUtils.convertTimeStampToDate(
                                                                        entityDetails?.updated_at,
                                                                        INTERNATIONAL_DATE_TIME_FORMAT
                                                                    )}
                                                                    <i className='ri-calendar-event-fill' />
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </ListGroupItem>
                                                <ListGroupItem>
                                                    <Row>
                                                        <Col sm='12'>
                                                            <Label>
                                                                {t('Created')}:
                                                            </Label>
                                                        </Col>
                                                        <Col sm='12'>
                                                            {entityDetailsIsFetching ? (
                                                                <div className='dt-field dt-skeleton dt-select-list' />
                                                            ) : (
                                                                <p className='d-flex justify-content-between align-items-center'>
                                                                    {dateUtils.convertTimeStampToDate(
                                                                        entityDetails?.created_at,
                                                                        INTERNATIONAL_DATE_TIME_FORMAT
                                                                    )}
                                                                    <i className='ri-calendar-event-fill' />
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </ListGroupItem>
                                            </ListGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {renderModals()}
        </div>
    )
}

const mapToState = (state) => {
    const { Organization } = state

    return {
        Organization
    }
}

export default withNamespaces()(
    withRouter(connect(mapToState, {})(EntityDetail))
)
