import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { withRouter, useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import overlayFactory from 'react-bootstrap-table2-overlay'
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator'
import { EMPTY_LIST, INTERNATIONAL_DATE_FORMAT } from 'src/common/constants'
import { toast } from 'react-toastify'
import Spinner from 'react-bootstrap/Spinner'
import TableFilter from '../../../../components/table-filter'
import Navigator from '../../../../components/navigator'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Container,
    Card,
    CardBody
} from 'reactstrap'
import CreateAndEditModal from '../components/CreateAndEditModal'
import DateUtils from 'src/services/utils/DateUtils'
import EntityManagementService from '../../../../api/EntityManagementService'
import CommonService from '../../../../api/CommonService'

const Entities = ({ t }) => {
    const [tableFilters, setTableFilters] = useState({
        pageIndex: 1,
        pageSize: 25,
        successorAnalyst: null,
        parent: null,
        sortField: null,
        sortOrder: null
    })

    const [showCreateEntityModal, setShowCreateEntityModal] = useState(false)
    const [selectedEntityToDelete, setSelectedEntityToDelete] = useState(null)
    const [selectedEntityToEdit, setSelectedEntityToEdit] = useState(null)

    const dateUtils = new DateUtils()
    const history = useHistory()

    const tableColumns = [
        {
            dataField: 'id',
            text: t('ID'),
            sort: false,
            key: 0,
            formatter: (cellContent, row) => {
                return <span>{row.id}</span>
            }
        },
        {
            dataField: 'dc_number',
            text: t('Debtor/Creditor number'),
            sort: false,
            key: 1
        },
        {
            dataField: 'short_name',
            text: t('Short Name'),
            sort: false,
            key: 2
        },
        {
            dataField: 'country',
            text: t('Country'),
            sort: false,
            key: 3,
            formatter: (cellContent, row) => {
                return row.country?.name
            }
        },
        {
            dataField: 'createdAt',
            text: t('Created on'),
            sort: true,
            key: 4,
            formatter: (cellContent, row) => {
                return (
                    <span>
                        {dateUtils.convertTimeStampToDate(
                            row.created_at,
                            INTERNATIONAL_DATE_FORMAT
                        )}
                    </span>
                )
            }
        },
        {
            dataField: 'analyst',
            text: t('Assign By Default'),
            sort: false,
            key: 5,
            formatter: (cellContent, row) => {
                return row.successor_analyst?.full_name
            }
        },
        {
            dataField: 'actions',
            text: 'Action',
            sort: false,
            key: 6,
            formatter: (cellContent, row) => {
                return (
                    <div className='d-flex justify-content-start'>
                        {/* <Button
                            onClick={() => setSelectedEntityToEdit(row)}
                            outline
                            color='primary'
                            className='border-0'
                        >
                            <i className='ri-pencil-line font-size-24' />
                        </Button> */}
                        <Button
                            onClick={() =>
                                history.push(
                                    `/admin/entity-management/entities/${row.id}/detail`
                                )
                            }
                            outline
                            color='primary'
                            className='border-0'
                        >
                            <i className='ri-eye-line font-size-24' />
                        </Button>
                        <Button
                            onClick={() => setSelectedEntityToDelete(row.id)}
                            outline
                            color='danger'
                            className='border-0'
                        >
                            <i className='ri-delete-bin-line font-size-24' />
                        </Button>
                    </div>
                )
            }
        }
    ]

    const {
        isFetching: entitiesAreFetching,
        refetch: refetchEntitiesList,
        data: entitiesList
    } = useQuery({
        queryKey: ['entity-management-fetch-all-entities-query', tableFilters],
        queryFn: async () => {
            const service = EntityManagementService.getInstance()

            return await service.fetchEntitesList({
                ...tableFilters
            })
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching entities list.'), {
                type: 'error'
            })
        }
    })

    const {
        isFetching: totalEntitiesAreFetching,
        data: totalEntities,
        refetch: refetchParentEntities
    } = useQuery({
        queryKey: ['entity-management-fetch-total-entities-query'],
        queryFn: async () => {
            const service = EntityManagementService.getInstance()

            return await service.fetchTotalEntities()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching entities list.'), {
                type: 'error'
            })
        }
    })

    const { isFetching: analystsAreFetching, data: analystsList } = useQuery({
        queryKey: ['entity-management-fetch-admins-query', tableFilters],
        queryFn: async () => {
            const service = CommonService.getInstance()

            return await service.fetchAnalystAndAdminAnalystList()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching analysts list.'), {
                type: 'error'
            })
        }
    })

    const { isFetching: countriesAreFetching, data: countriesList } = useQuery({
        queryKey: ['entity-management-fetch-countries-query', tableFilters],
        queryFn: async () => {
            const service = CommonService.getInstance()

            return await service.fetchCountriesList()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching countries list.'), {
                type: 'error'
            })
        }
    })

    const { isLoading: createEntityIsLoading, mutate: mutateEntityCreation } =
        useMutation({
            mutationFn: async (payload) => {
                const service = EntityManagementService.getInstance()

                return await service.create(payload)
            },
            onSuccess: () => {
                setShowCreateEntityModal(false)
                refetchEntitiesList()
                refetchParentEntities()

                toast(t('Entity created successfully.'), {
                    type: 'success'
                })
            },
            onError: (error) => {
                if (error?.response?.data?.error) {
                    toast(t(error?.response?.data?.error), {
                        type: 'error'
                    })
                } else {
                    toast(t('Failed to create entity.'), {
                        type: 'error'
                    })
                }
            }
        })

    const { isLoading: editEntityIsLoading, mutate: mutateEntityEdit } =
        useMutation({
            mutationFn: async (payload) => {
                const service = EntityManagementService.getInstance()

                return await service.edit(selectedEntityToEdit.id, payload)
            },
            onSuccess: () => {
                setSelectedEntityToEdit(null)
                refetchEntitiesList()
                refetchParentEntities()

                toast(t('Entity updated successfully.'), {
                    type: 'success'
                })
            },
            onError: (error) => {
                if (error?.response?.data?.error) {
                    toast(t(error?.response?.data?.error), {
                        type: 'error'
                    })
                } else {
                    toast(t('Failed to edit entity.'), {
                        type: 'error'
                    })
                }
            }
        })

    const { isLoading: deleteEntityIsLoading, mutate: mutateEntityDelete } =
        useMutation({
            mutationFn: async (entityId) => {
                const service = EntityManagementService.getInstance()

                return await service.delete(entityId)
            },
            onSuccess: () => {
                setSelectedEntityToDelete(null)
                refetchEntitiesList()

                toast(t('Entity deleted successfully.'), {
                    type: 'success'
                })
            },
            onError: () => {
                toast(t('Failed to delete entity.'), {
                    type: 'error'
                })
            }
        })

    const analystMemoList = React.useMemo(() => {
        return (analystsList || []).map((item) => {
            return {
                label: `${item.first_name} ${item.last_name}`,
                value: item.id
            }
        })
    }, [analystsList])

    const countriesMemoList = React.useMemo(() => {
        return (countriesList || []).map((item) => {
            return {
                label: item.name,
                value: item.id
            }
        })
    }, [countriesList])

    const totalEntitiesMemoList = React.useMemo(() => {
        return (totalEntities?.legalEntities || []).map((item) => {
            return {
                label: item.title,
                value: item.id
            }
        })
    }, [totalEntities])

    const tableFilterTabs = React.useMemo(() => {
        return [
            {
                type: 'radio_list',
                name: 'successorAnalyst',
                title: 'Analyst',
                isLoading: analystsAreFetching,
                defaultValue: tableFilters?.successorAnalyst,
                data: analystsList || [],
                formatter: (item) => {
                    return `${item.first_name} ${item.last_name}`
                }
            },
            {
                type: 'radio_list',
                name: 'parent',
                title: 'Parent Entity',
                isLoading: totalEntitiesAreFetching,
                defaultValue: tableFilters?.parent,
                data: totalEntities?.legalEntities || [],
                formatter: (item) => {
                    return item.title
                }
            }
        ]
    }, [
        analystsAreFetching,
        totalEntitiesAreFetching,
        analystsList,
        totalEntities,
        tableFilters.successorAnalyst,
        tableFilters.parent
    ])

    const NoDataIndication = () => {
        if (!entitiesAreFetching && !parseInt(entitiesList?.itemsCount)) {
            return (
                <div className='alert m-0' role='alert'>
                    <p
                        style={{
                            textAlign: 'center',
                            marginBottom: 0
                        }}
                    >
                        {t(EMPTY_LIST)}
                    </p>
                </div>
            )
        }

        return <React.Fragment />
    }

    const onTableFilterOkButtonClicked = (filters) => {
        if (filters) {
            const result = {}

            if ('successorAnalyst' in filters) {
                result.successorAnalyst =
                    filters.successorAnalyst !== 'all'
                        ? filters.successorAnalyst
                        : undefined
            }

            if ('parent' in filters) {
                result.parent =
                    filters.parent !== 'all' ? filters.parent : undefined
            }

            setTableFilters({
                ...tableFilters,
                ...result
            })
        } else {
            setTableFilters({
                pageIndex: tableFilterTabs.pageIndex,
                pageSize: tableFilterTabs.pageSize,
                sortField: tableFilterTabs.sortField,
                sortOrder: tableFilterTabs.sortOrder,
                successorAnalyst: null,
                parent: null
            })
        }
    }

    const handleTableChange = (type, data) => {
        switch (type) {
            case 'pagination': {
                const { page, sizePerPage } = data

                setTableFilters({
                    ...tableFilters,
                    pageIndex: page,
                    pageSize: sizePerPage
                })
                break
            }
            case 'sort': {
                const { sortField, sortOrder } = data

                setTableFilters({
                    ...tableFilters,
                    sortField,
                    sortOrder
                })
                break
            }
            default:
                return false
        }
    }

    const renderModals = () => {
        if (
            entitiesAreFetching ||
            countriesAreFetching ||
            analystsAreFetching ||
            totalEntitiesAreFetching
        ) {
            return null
        }

        return (
            <React.Fragment>
                <Modal
                    size='lg'
                    scrollable
                    isOpen={!!selectedEntityToDelete}
                    backdrop='static'
                >
                    <ModalHeader toggle={() => setSelectedEntityToDelete(null)}>
                        {t('Delete Entity')}
                    </ModalHeader>

                    <ModalBody>
                        <p>
                            {t('Are you sure you want to delete this entity?')}
                        </p>

                        <ModalFooter>
                            <Button
                                disabled={deleteEntityIsLoading}
                                color='primary'
                                outline
                                className='waves-effect waves-light'
                                type='button'
                                onClick={() => {
                                    setSelectedEntityToDelete(null)
                                }}
                            >
                                {t('Cancel')}
                            </Button>

                            <Button
                                disabled={deleteEntityIsLoading}
                                color='danger'
                                type='button'
                                className='waves-effect waves-light'
                                onClick={() =>
                                    mutateEntityDelete(selectedEntityToDelete)
                                }
                            >
                                {deleteEntityIsLoading && (
                                    <Spinner
                                        animation='border'
                                        variant='danger'
                                        size='sm'
                                        className='me-1'
                                    />
                                )}
                                {t('Delete')}
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>

                <CreateAndEditModal
                    show={showCreateEntityModal || selectedEntityToEdit}
                    toggle={() => {
                        setShowCreateEntityModal(false)
                        setSelectedEntityToEdit(null)
                    }}
                    onSubmit={(values, action) => {
                        if (action === 'edit') {
                            mutateEntityEdit(values)
                        } else {
                            mutateEntityCreation(values)
                        }
                    }}
                    analystMemoList={analystMemoList}
                    countriesMemoList={countriesMemoList}
                    parentEntitiesMemoList={totalEntitiesMemoList}
                    entity={selectedEntityToEdit}
                    submitInProgress={
                        createEntityIsLoading || editEntityIsLoading
                    }
                />
            </React.Fragment>
        )
    }

    return (
        <div className='page-content'>
            {renderModals()}

            <Container fluid>
                <Row>
                    <Col lg='12' className='mb-4'>
                        <Navigator
                            backButtonOptions={(props) => {
                                return {
                                    ...props,
                                    enable: true
                                }
                            }}
                            breadCrumbs={[
                                {
                                    title: 'Entity Management',
                                    link: '/admin/entity-management/entities'
                                },
                                {
                                    title: 'Entities',
                                    link: `#`
                                }
                            ]}
                        />
                    </Col>

                    <Col lg='12'>
                        <Card>
                            <CardBody>
                                {tableColumns && tableColumns.length > 0 && (
                                    <PaginationProvider
                                        pagination={paginationFactory({
                                            custom: true,
                                            page: tableFilters.pageIndex,
                                            sizePerPage: tableFilters.pageSize,
                                            totalSize:
                                                entitiesList?.itemsCount || 0,
                                            withFirstAndLast: false,
                                            alwaysShowAllBtns: true,
                                            prePageText: (
                                                <span>
                                                    <i className='ri-arrow-left-s-line' />{' '}
                                                    {t('Back')}
                                                </span>
                                            ),
                                            nextPageText: (
                                                <span>
                                                    {t('Next')}{' '}
                                                    <i className='ri-arrow-right-s-line' />
                                                </span>
                                            ),
                                            prePageTitle: t('Pre page'),
                                            firstPageTitle: t('Next page'),
                                            showTotal: true,
                                            hideSizePerPage: false,
                                            sizePerPageList: [
                                                {
                                                    text: '25',
                                                    value: 25
                                                },
                                                {
                                                    text: '50',
                                                    value: 50
                                                }
                                            ]
                                        })}
                                    >
                                        {({
                                            paginationProps,
                                            paginationTableProps
                                        }) => (
                                            <React.Fragment>
                                                <Row className='mb-5'>
                                                    <Col sm='12' md='4'>
                                                        {/* <Button
                                                            color='primary'
                                                            outline
                                                        >
                                                            {t(
                                                                'Download CSV Template'
                                                            )}
                                                        </Button> */}
                                                    </Col>
                                                    <Col
                                                        sm='12'
                                                        md='8'
                                                        className='d-flex justify-content-end'
                                                    >
                                                        <TableFilter
                                                            defaultActiveTab='successorAnalyst'
                                                            tabs={
                                                                tableFilterTabs
                                                            }
                                                            onOkButtonClicked={
                                                                onTableFilterOkButtonClicked
                                                            }
                                                            className='me-3'
                                                        />
                                                        {/* <Button
                                                            color='primary'
                                                            outline
                                                            className='me-3'
                                                        >
                                                            {t('Upload CSV')}
                                                        </Button> */}
                                                        <Button
                                                            onClick={() =>
                                                                setShowCreateEntityModal(
                                                                    true
                                                                )
                                                            }
                                                            color='primary'
                                                        >
                                                            {t('Add Entity')}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm='12'>
                                                        <BootstrapTable
                                                            remote={{
                                                                pagination: true,
                                                                filter: false,
                                                                sort: true,
                                                                cellEdit: false,
                                                                search: false
                                                            }}
                                                            loading={
                                                                entitiesAreFetching
                                                            }
                                                            overlay={overlayFactory(
                                                                {
                                                                    spinner: (
                                                                        <Spinner
                                                                            animation='border'
                                                                            variant='primary'
                                                                            size='md'
                                                                        />
                                                                    ),
                                                                    text: 'Loading...'
                                                                }
                                                            )}
                                                            onTableChange={
                                                                handleTableChange
                                                            }
                                                            defaultSorted={[]}
                                                            keyField='id'
                                                            responsive
                                                            bordered={false}
                                                            data={
                                                                entitiesList?.legalEntities ||
                                                                []
                                                            }
                                                            striped
                                                            columns={
                                                                tableColumns
                                                            }
                                                            wrapperClasses='table-responsive'
                                                            classes='table tpdd-table'
                                                            headerWrapperClasses='thead-light'
                                                            style={{
                                                                overflowX:
                                                                    'auto'
                                                            }}
                                                            noDataIndication={() => (
                                                                <NoDataIndication />
                                                            )}
                                                            {...paginationTableProps}
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm='12' md='6'>
                                                        <div className='tpdd-pagination-style-1'>
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />

                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </PaginationProvider>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const mapToState = (state) => {
    const { Organization } = state

    return {
        Organization
    }
}

export default withNamespaces()(withRouter(connect(mapToState, {})(Entities)))
