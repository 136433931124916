import axios from 'axios'
import { API_BASE_URL } from '../constants/'

export default class DivisionManagementService {
    static instance

    static getInstance() {
        if (!DivisionManagementService.instance) {
            DivisionManagementService.instance = new DivisionManagementService()
        }
        return DivisionManagementService.instance
    }

    async fetchDivisionsList(entityId, params = {}) {
        const response = await axios.get(
            `${API_BASE_URL}/entity_management/division/legal_entity/${entityId}/list`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || []
    }

    async create(entityId, payload = {}) {
        const response = await axios.post(
            `${API_BASE_URL}/entity_management/division/add`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data
    }

    async delete(entityId, divisionId) {
        const response = await axios.delete(
            `${API_BASE_URL}/entity_management/division/remove/${divisionId}`,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async edit(entityId, divisionId, payload = {}) {
        const response = await axios.post(
            `${API_BASE_URL}/entity_management/division/edit/${divisionId}`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data
    }
}
