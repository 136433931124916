import axios from 'axios'
import {
    API_URL_GET_ANALYST_AND_ADMIN_LIST,
    API_URL_FETCH_COUNTRIES_LIST
} from '../constants/'

export default class CommonService {
    static instance

    static getInstance() {
        if (!CommonService.instance) {
            CommonService.instance = new CommonService()
        }
        return CommonService.instance
    }

    async fetchAnalystAndAdminAnalystList(payload = {}) {
        const response = await axios.post(
            API_URL_GET_ANALYST_AND_ADMIN_LIST,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data
    }

    async fetchCountriesList() {
        const response = await axios.post(API_URL_FETCH_COUNTRIES_LIST, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                    'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                )}`
            }
        })

        return response.data.data
    }
}
