import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import {
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap'
import { toast } from 'react-toastify'
import { useQuery, useMutation } from '@tanstack/react-query'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'
import EntityAddressManagementService from '../../../../../../api/EntityAddressManagementService'
import CommonService from '../../../../../../api/CommonService'

const Addresses = ({ t, entityId, list, isFetching, onRefreshList }) => {
    const avFormRef = React.useRef()

    const [selectedCountry, setSelectedCountry] = React.useState(null)
    const [selectedAddressToDelete, setSelectedAddressToDelete] =
        React.useState(null)
    const [selectedAddressToEdit, setSelectedAddressToEdit] =
        React.useState(null)

    const { isFetching: countriesAreFetching, data: countriesList } = useQuery({
        queryKey: ['entity-management-fetch-countries-query'],
        queryFn: async () => {
            const service = CommonService.getInstance()

            return await service.fetchCountriesList()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching countries list.'), {
                type: 'error'
            })
        }
    })

    const { isLoading: createAddressIsLoading, mutate: mutateAddressCreation } =
        useMutation({
            mutationFn: async (payload) => {
                const service = EntityAddressManagementService.getInstance()

                return await service.create(entityId, payload)
            },
            onSuccess: () => {
                setSelectedCountry(null)
                onRefreshList && onRefreshList()

                toast(t('Address created successfully.'), {
                    type: 'success'
                })
            },
            onError: () => {
                toast(t('Failed to create address.'), {
                    type: 'error'
                })
            }
        })

    const { isLoading: editAddressIsLoading, mutate: mutateAddressEdit } =
        useMutation({
            mutationFn: async (payload) => {
                const service = EntityAddressManagementService.getInstance()

                return await service.edit(
                    entityId,
                    selectedAddressToEdit.id,
                    payload
                )
            },
            onSuccess: () => {
                setSelectedAddressToEdit(null)
                onRefreshList && onRefreshList()

                toast(t('Address updated successfully.'), {
                    type: 'success'
                })
            },
            onError: () => {
                toast(t('Failed to edit address.'), {
                    type: 'error'
                })
            }
        })

    const { isLoading: deleteAddressIsLoading, mutate: mutateAddressDelete } =
        useMutation({
            mutationFn: async (addressId) => {
                const service = EntityAddressManagementService.getInstance()

                return await service.delete(entityId, addressId)
            },
            onSuccess: () => {
                setSelectedAddressToDelete(null)
                onRefreshList && onRefreshList()

                toast(t('Address deleted successfully.'), {
                    type: 'success'
                })
            },
            onError: () => {
                toast(t('Failed to delete address.'), {
                    type: 'error'
                })
            }
        })

    const countriesMemoList = React.useMemo(() => {
        return (countriesList || []).map((item) => {
            return {
                label: item.name,
                value: item.id
            }
        })
    }, [countriesList])

    React.useEffect(() => {
        if (selectedAddressToEdit) {
            setSelectedCountry({
                value: selectedAddressToEdit?.country?.id,
                label: selectedAddressToEdit?.country?.name
            })
        } else {
            setSelectedCountry(null)
        }
    }, [selectedAddressToEdit])

    const handleAddressSubmit = (e, values) => {
        if (selectedAddressToEdit) {
            mutateAddressEdit({
                ...values.edit
            })
        } else {
            mutateAddressCreation({
                ...values.create,
                legalEntity: entityId
            })
        }
    }

    const renderModals = () => {
        if (isFetching) {
            return null
        }

        return (
            <React.Fragment>
                <Modal
                    size='lg'
                    scrollable
                    isOpen={!!selectedAddressToDelete}
                    backdrop='static'
                >
                    <ModalHeader
                        toggle={() => setSelectedAddressToDelete(null)}
                    >
                        {t('Delete Address')}
                    </ModalHeader>

                    <ModalBody>
                        <p>
                            {t('Are you sure you want to delete this address?')}
                        </p>

                        <ModalFooter>
                            <Button
                                disabled={deleteAddressIsLoading}
                                color='primary'
                                outline
                                className='waves-effect waves-light'
                                type='button'
                                onClick={() => {
                                    setSelectedAddressToDelete(null)
                                }}
                            >
                                {t('Cancel')}
                            </Button>

                            <Button
                                disabled={deleteAddressIsLoading}
                                color='danger'
                                type='button'
                                className='waves-effect waves-light'
                                onClick={() =>
                                    mutateAddressDelete(selectedAddressToDelete)
                                }
                            >
                                {deleteAddressIsLoading && (
                                    <Spinner
                                        animation='border'
                                        variant='danger'
                                        size='sm'
                                        className='me-1'
                                    />
                                )}
                                {t('Delete')}
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }

    const renderAddressesTable = () => {
        const items = list || []

        return items.map((address, index) => {
            return (
                <tr key={index}>
                    <td>
                        {address?.id === selectedAddressToEdit?.id ? (
                            <AvField
                                placeholder='City'
                                name='edit[city]'
                                type='text'
                                errorMessage={t('This field cannot be blank')}
                                className='form-control'
                                defaultValue={address?.city}
                                validate={{
                                    required: {
                                        value: true
                                    }
                                }}
                            />
                        ) : (
                            <React.Fragment>{address?.city}</React.Fragment>
                        )}
                    </td>
                    <td>
                        {address?.id === selectedAddressToEdit?.id ? (
                            <React.Fragment>
                                <Select
                                    className='mb-2'
                                    isDisabled={countriesAreFetching}
                                    isLoading={countriesAreFetching}
                                    placeholder={t('Select') + '...'}
                                    classNamePrefix='select2-selection'
                                    options={countriesMemoList}
                                    menuPortalTarget={document.body}
                                    value={selectedCountry}
                                    onChange={(e) => {
                                        const input =
                                            avFormRef.current._inputs[
                                                'edit[country]'
                                            ]
                                        if (input) {
                                            input.value = e.value
                                            input.validate()
                                        }
                                        setSelectedCountry(e)
                                    }}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999
                                        })
                                    }}
                                />
                                <AvField
                                    type='hidden'
                                    name='edit[country]'
                                    errorMessage={t(
                                        'This field cannot be blank'
                                    )}
                                    value={selectedCountry?.value}
                                    validate={{
                                        required: {
                                            value: true
                                        }
                                    }}
                                />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {address?.country?.name}
                            </React.Fragment>
                        )}
                    </td>
                    <td>
                        {address?.id === selectedAddressToEdit?.id ? (
                            <AvField
                                placeholder='Extra'
                                name='edit[address]'
                                type='text'
                                errorMessage={t('This field cannot be blank')}
                                className='form-control'
                                defaultValue={address?.address}
                                validate={{
                                    required: {
                                        value: true
                                    }
                                }}
                            />
                        ) : (
                            <React.Fragment>{address?.address}</React.Fragment>
                        )}
                    </td>
                    <td>
                        {address?.id === selectedAddressToEdit?.id ? (
                            <React.Fragment>
                                <Button
                                    disabled={editAddressIsLoading}
                                    color='primary'
                                    type='submit'
                                    className='waves-effect waves-light me-1'
                                >
                                    {editAddressIsLoading && (
                                        <Spinner
                                            animation='border'
                                            variant='danger'
                                            size='sm'
                                            className='me-1'
                                        />
                                    )}
                                    {t('Save')}
                                </Button>
                                <Button
                                    disabled={editAddressIsLoading}
                                    color='danger'
                                    type='button'
                                    size='sm'
                                    outline
                                    className='border-0'
                                    onClick={() => {
                                        setSelectedAddressToEdit(null)
                                    }}
                                >
                                    <i className='ri-close-line font-size-24' />
                                </Button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Button
                                    type='button'
                                    size='sm'
                                    color='primary'
                                    outline
                                    className='border-0'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setSelectedAddressToEdit(address)
                                    }}
                                >
                                    <i className='ri-pencil-line font-size-24' />
                                </Button>
                                <Button
                                    type='button'
                                    size='sm'
                                    color='danger'
                                    outline
                                    className='border-0'
                                    onClick={() =>
                                        setSelectedAddressToDelete(address.id)
                                    }
                                >
                                    <i className='ri-delete-bin-line font-size-24' />
                                </Button>
                            </React.Fragment>
                        )}
                    </td>
                </tr>
            )
        })
    }

    const renderCreationRow = () => {
        return (
            <tr key='creation-row'>
                <td>
                    <AvField
                        disabled={!!selectedAddressToEdit}
                        placeholder='City'
                        name='create[city]'
                        type='text'
                        errorMessage={t('This field cannot be blank')}
                        className='form-control'
                        validate={{
                            required: {
                                value: !selectedAddressToEdit
                            }
                        }}
                    />
                </td>
                <td>
                    <Select
                        className='mb-2'
                        isDisabled={
                            countriesAreFetching || !!selectedAddressToEdit
                        }
                        isLoading={countriesAreFetching}
                        placeholder={t('Select') + '...'}
                        classNamePrefix='select2-selection'
                        options={countriesMemoList}
                        menuPortalTarget={document.body}
                        value={!selectedAddressToEdit ? selectedCountry : null}
                        onChange={(e) => {
                            const input =
                                avFormRef.current._inputs['create[country]']
                            if (input) {
                                input.value = e.value
                                input.validate()
                            }
                            setSelectedCountry(e)
                        }}
                        styles={{
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999
                            })
                        }}
                    />
                    <AvField
                        type='hidden'
                        name='create[country]'
                        errorMessage={t('This field cannot be blank')}
                        defaultValue={selectedCountry?.value}
                        validate={{
                            required: {
                                value: !selectedAddressToEdit
                            }
                        }}
                    />
                </td>
                <td>
                    <AvField
                        disabled={!!selectedAddressToEdit}
                        placeholder='Extra'
                        name='create[address]'
                        type='text'
                        errorMessage={t('This field cannot be blank')}
                        className='form-control'
                        validate={{
                            required: {
                                value: !selectedAddressToEdit
                            }
                        }}
                    />
                </td>
                <td>
                    <Button
                        outline
                        color='primary'
                        type='submit'
                        className='waves-effect waves-light'
                        disabled={
                            !!selectedAddressToEdit || createAddressIsLoading
                        }
                    >
                        {createAddressIsLoading && (
                            <Spinner
                                animation='border'
                                variant='danger'
                                size='sm'
                                className='me-1'
                            />
                        )}
                        {t('Add Location')}
                    </Button>
                </td>
            </tr>
        )
    }

    return (
        <div>
            {renderModals()}
            <AvForm
                className='needs-validation'
                onValidSubmit={handleAddressSubmit}
                ref={avFormRef}
            >
                <Table borderless>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: '180px'
                                }}
                            >
                                {t('city')}
                            </th>
                            <th
                                style={{
                                    width: '180px'
                                }}
                            >
                                {t('Country')}
                            </th>
                            <th>{t('Extra')}</th>
                            <th>{t('Action')}</th>
                        </tr>
                    </thead>
                    {isFetching ? (
                        <tbody>
                            <tr>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            {renderAddressesTable()}
                            {renderCreationRow()}
                        </tbody>
                    )}
                </Table>
            </AvForm>
        </div>
    )
}

export default withNamespaces()(Addresses)
