import axios from 'axios'
import { API_BASE_URL } from '../constants/'

export default class EntityAddressManagementService {
    static instance

    static getInstance() {
        if (!EntityAddressManagementService.instance) {
            EntityAddressManagementService.instance =
                new EntityAddressManagementService()
        }
        return EntityAddressManagementService.instance
    }

    async create(entityId, payload = {}) {
        const response = await axios.post(
            `${API_BASE_URL}/entity_management/legal_location/add`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data
    }

    async delete(entityId, addressId) {
        const response = await axios.delete(
            `${API_BASE_URL}/entity_management/legal_location/remove/${addressId}`,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async edit(entityId, addressId, payload = {}) {
        const response = await axios.post(
            `${API_BASE_URL}/entity_management/legal_location/edit/${addressId}`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data
    }
}
