import VerticalLayout from 'src/components/VerticalLayout'
import Entities from '../pages/admin/entities/list'
import EntityDetail from '../pages/admin/entities/detail'

const adminRoutes = [
    {
        path: '/admin/entity-management/entities/:entityId/detail',
        component: EntityDetail,
        layout: VerticalLayout,
        type: 'admin',
        modules: ['wb', 'cm', 'gd', 'tp']
    },
    {
        path: '/admin/entity-management/entities',
        component: Entities,
        layout: VerticalLayout,
        type: 'admin',
        modules: ['wb', 'cm', 'gd', 'tp']
    }
]

export default adminRoutes

export { adminRoutes }
