import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import {
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap'
import { toast } from 'react-toastify'
import { useQuery, useMutation } from '@tanstack/react-query'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'
import DivisionManagementService from '../../../../../../api/DivisionManagementService'
import CommonService from '../../../../../../api/CommonService'

const Divisions = ({ t, entityId }) => {
    const avFormRef = React.useRef()

    const [selectedAnalyst, setSelectedAnalyst] = React.useState(null)
    const [selectedDivisionToDelete, setSelectedDivisionToDelete] =
        React.useState(null)
    const [selectedDivisionToEdit, setSelectedDivisionToEdit] =
        React.useState(null)

    const {
        isFetching: divisionsAreFetching,
        refetch: refetchDivisionsList,
        data: divisionsList
    } = useQuery({
        queryKey: ['entity-management-fetch-entity-divisions-query'],
        queryFn: async () => {
            const service = DivisionManagementService.getInstance()

            return await service.fetchDivisionsList(entityId, {
                pageIndex: 0,
                pageSize: 100
            })
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching divisions list.'), {
                type: 'error'
            })
        }
    })

    const { isFetching: analystsAreFetching, data: analystsList } = useQuery({
        queryKey: ['entity-management-fetch-admins-query'],
        queryFn: async () => {
            const service = CommonService.getInstance()

            return await service.fetchAnalystAndAdminAnalystList()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching analysts list.'), {
                type: 'error'
            })
        }
    })

    const {
        isLoading: createDivisionIsLoading,
        mutate: mutateDivisionCreation
    } = useMutation({
        mutationFn: async (payload) => {
            const service = DivisionManagementService.getInstance()

            return await service.create(entityId, payload)
        },
        onSuccess: () => {
            setSelectedAnalyst(null)
            refetchDivisionsList()

            toast(t('Division created successfully.'), {
                type: 'success'
            })
        },
        onError: () => {
            toast(t('Failed to create division.'), {
                type: 'error'
            })
        }
    })

    const { isLoading: editDivisionIsLoading, mutate: mutateDivisionEdit } =
        useMutation({
            mutationFn: async (payload) => {
                const service = DivisionManagementService.getInstance()

                return await service.edit(
                    entityId,
                    selectedDivisionToEdit.id,
                    payload
                )
            },
            onSuccess: () => {
                setSelectedDivisionToEdit(null)
                refetchDivisionsList()

                toast(t('Division updated successfully.'), {
                    type: 'success'
                })
            },
            onError: () => {
                toast(t('Failed to edit division.'), {
                    type: 'error'
                })
            }
        })

    const { isLoading: deleteDivisionIsLoading, mutate: mutateDivisionDelete } =
        useMutation({
            mutationFn: async (divisionId) => {
                const service = DivisionManagementService.getInstance()

                return await service.delete(entityId, divisionId)
            },
            onSuccess: () => {
                setSelectedDivisionToDelete(null)
                refetchDivisionsList()

                toast(t('Division deleted successfully.'), {
                    type: 'success'
                })
            },
            onError: () => {
                toast(t('Failed to delete division.'), {
                    type: 'error'
                })
            }
        })

    const analystMemoList = React.useMemo(() => {
        return (analystsList || []).map((item) => {
            return {
                label: `${item.first_name} ${item.last_name}`,
                value: item.id
            }
        })
    }, [analystsList])

    React.useEffect(() => {
        if (selectedDivisionToEdit) {
            setSelectedAnalyst({
                value: selectedDivisionToEdit?.successor_analyst?.id,
                label: selectedDivisionToEdit?.successor_analyst?.full_name
            })
        } else {
            setSelectedAnalyst(null)
        }
    }, [selectedDivisionToEdit])

    const handleCreateDivisionSubmit = (values) => {
        mutateDivisionCreation({
            ...values,
            legalEntity: entityId
        })
    }

    const handleEditDivisionSubmit = (values) => {
        mutateDivisionEdit({
            ...values,
            legalEntity: entityId
        })
    }

    const handleDivisionSubmit = (e, values) => {
        if (selectedDivisionToEdit) {
            handleEditDivisionSubmit(values.edit)
        } else {
            handleCreateDivisionSubmit(values.create)
        }
    }

    const renderModals = () => {
        if (divisionsAreFetching) {
            return null
        }

        return (
            <React.Fragment>
                <Modal
                    size='lg'
                    scrollable
                    isOpen={!!selectedDivisionToDelete}
                    backdrop='static'
                >
                    <ModalHeader
                        toggle={() => setSelectedDivisionToDelete(null)}
                    >
                        {t('Delete Division')}
                    </ModalHeader>

                    <ModalBody>
                        <p>
                            {t(
                                'Are you sure you want to delete this division?'
                            )}
                        </p>

                        <ModalFooter>
                            <Button
                                disabled={deleteDivisionIsLoading}
                                color='primary'
                                outline
                                className='waves-effect waves-light'
                                type='button'
                                onClick={() => {
                                    setSelectedDivisionToDelete(null)
                                }}
                            >
                                {t('Cancel')}
                            </Button>

                            <Button
                                disabled={deleteDivisionIsLoading}
                                color='danger'
                                type='button'
                                className='waves-effect waves-light'
                                onClick={() =>
                                    mutateDivisionDelete(
                                        selectedDivisionToDelete
                                    )
                                }
                            >
                                {deleteDivisionIsLoading && (
                                    <Spinner
                                        animation='border'
                                        variant='danger'
                                        size='sm'
                                        className='me-1'
                                    />
                                )}
                                {t('Delete')}
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }

    const renderDivisionsTable = () => {
        const items = divisionsList?.divisions || []

        return items.map((division, index) => {
            return (
                <tr key={index}>
                    <td>
                        {division?.id === selectedDivisionToEdit?.id ? (
                            <AvField
                                placeholder='Division'
                                name='edit[title]'
                                type='text'
                                errorMessage={t('This field cannot be blank')}
                                className='form-control'
                                defaultValue={division?.title}
                                validate={{
                                    required: {
                                        value: true
                                    }
                                }}
                            />
                        ) : (
                            <React.Fragment>{division?.title}</React.Fragment>
                        )}
                    </td>
                    <td>
                        {division?.id === selectedDivisionToEdit?.id ? (
                            <AvField
                                placeholder='Division'
                                name='edit[dcNumber]'
                                type='text'
                                errorMessage={t('This field cannot be blank')}
                                className='form-control'
                                defaultValue={division?.dc_number}
                                validate={{
                                    required: {
                                        value: true
                                    }
                                }}
                            />
                        ) : (
                            <React.Fragment>
                                {division?.dc_number}
                            </React.Fragment>
                        )}
                    </td>
                    <td>
                        {division?.id === selectedDivisionToEdit?.id ? (
                            <React.Fragment>
                                <Select
                                    className='mb-2'
                                    isDisabled={analystsAreFetching}
                                    isLoading={analystsAreFetching}
                                    placeholder={t('Select') + '...'}
                                    classNamePrefix='select2-selection'
                                    options={analystMemoList}
                                    menuPortalTarget={document.body}
                                    value={selectedAnalyst}
                                    onChange={(e) => {
                                        const input =
                                            avFormRef.current._inputs[
                                                'edit[successorAnalyst]'
                                            ]
                                        if (input) {
                                            input.value = e.value
                                            input.validate()
                                        }
                                        setSelectedAnalyst(e)
                                    }}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999
                                        })
                                    }}
                                />
                                <AvField
                                    type='hidden'
                                    name='edit[successorAnalyst]'
                                    errorMessage={t(
                                        'This field cannot be blank'
                                    )}
                                    value={selectedAnalyst?.value}
                                    validate={{
                                        required: {
                                            value: true
                                        }
                                    }}
                                />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {division?.successor_analyst?.full_name}
                            </React.Fragment>
                        )}
                    </td>
                    <td>
                        {division?.id === selectedDivisionToEdit?.id ? (
                            <React.Fragment>
                                <Button
                                    disabled={editDivisionIsLoading}
                                    color='primary'
                                    type='submit'
                                    className='waves-effect waves-light me-1'
                                >
                                    {editDivisionIsLoading && (
                                        <Spinner
                                            animation='border'
                                            variant='danger'
                                            size='sm'
                                            className='me-1'
                                        />
                                    )}
                                    {t('Save')}
                                </Button>
                                <Button
                                    disabled={editDivisionIsLoading}
                                    color='danger'
                                    type='button'
                                    size='sm'
                                    outline
                                    className='border-0'
                                    onClick={() => {
                                        setSelectedDivisionToEdit(null)
                                    }}
                                >
                                    <i className='ri-close-line font-size-24' />
                                </Button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Button
                                    type='button'
                                    size='sm'
                                    color='primary'
                                    outline
                                    className='border-0'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setSelectedDivisionToEdit(division)
                                    }}
                                >
                                    <i className='ri-pencil-line font-size-24' />
                                </Button>
                                <Button
                                    type='button'
                                    size='sm'
                                    color='danger'
                                    outline
                                    className='border-0'
                                    onClick={() =>
                                        setSelectedDivisionToDelete(division.id)
                                    }
                                >
                                    <i className='ri-delete-bin-line font-size-24' />
                                </Button>
                            </React.Fragment>
                        )}
                    </td>
                </tr>
            )
        })
    }

    const renderCreationRow = () => {
        return (
            <tr key='creation-row'>
                <td>
                    <AvField
                        disabled={!!selectedDivisionToEdit}
                        placeholder='Division'
                        name='create[title]'
                        type='text'
                        errorMessage={t('This field cannot be blank')}
                        className='form-control'
                        validate={{
                            required: {
                                value: !selectedDivisionToEdit
                            }
                        }}
                    />
                </td>
                <td>
                    <AvField
                        disabled={!!selectedDivisionToEdit}
                        placeholder='Debtor/Creditor number'
                        name='create[dcNumber]'
                        type='text'
                        errorMessage={t('This field cannot be blank')}
                        className='form-control'
                        validate={{
                            required: {
                                value: !selectedDivisionToEdit
                            }
                        }}
                    />
                </td>
                <td>
                    <Select
                        className='mb-2'
                        isDisabled={
                            analystsAreFetching || !!selectedDivisionToEdit
                        }
                        isLoading={analystsAreFetching}
                        placeholder={t('Select') + '...'}
                        classNamePrefix='select2-selection'
                        options={analystMemoList}
                        menuPortalTarget={document.body}
                        value={!selectedDivisionToEdit ? selectedAnalyst : null}
                        onChange={(e) => {
                            const input =
                                avFormRef.current._inputs[
                                    'create[successorAnalyst]'
                                ]
                            if (input) {
                                input.value = e.value
                                input.validate()
                            }
                            setSelectedAnalyst(e)
                        }}
                        styles={{
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999
                            })
                        }}
                    />
                    <AvField
                        type='hidden'
                        name='create[successorAnalyst]'
                        errorMessage={t('This field cannot be blank')}
                        defaultValue={selectedAnalyst?.value}
                        validate={{
                            required: {
                                value: !selectedDivisionToEdit
                            }
                        }}
                    />
                </td>
                <td>
                    <Button
                        outline
                        color='primary'
                        type='submit'
                        className='waves-effect waves-light'
                        disabled={
                            !!selectedDivisionToEdit || createDivisionIsLoading
                        }
                    >
                        {createDivisionIsLoading && (
                            <Spinner
                                animation='border'
                                variant='danger'
                                size='sm'
                                className='me-1'
                            />
                        )}
                        {t('Add Division')}
                    </Button>
                </td>
            </tr>
        )
    }

    return (
        <div>
            {renderModals()}
            <AvForm
                className='needs-validation'
                onValidSubmit={handleDivisionSubmit}
                ref={avFormRef}
            >
                <Table borderless>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: '200px'
                                }}
                            >
                                {t('Division')}
                            </th>
                            <th
                                style={{
                                    width: '180px'
                                }}
                            >
                                {t('Debtor/Creditor number')}
                            </th>
                            <th>{t('Assign By Default')}</th>
                            <th>{t('Action')}</th>
                        </tr>
                    </thead>
                    {divisionsAreFetching ? (
                        <tbody>
                            <tr>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                                <td>
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            {renderDivisionsTable()}
                            {renderCreationRow()}
                        </tbody>
                    )}
                </Table>
            </AvForm>
        </div>
    )
}

export default withNamespaces()(Divisions)
